import React from 'react';
import { PathService } from '../../services';
import { OurInsightsRiskSnapshotViewContext, TemplateProps } from '../../types';
import { OurInsightsRiskSnapshotView } from '../../views';

const OurInsightsRiskSnapshot: React.FunctionComponent<
  TemplateProps<OurInsightsRiskSnapshotViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurInsightsRiskSnapshotView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
      relatedPosts={JSON.parse(pageContext.relatedPosts)}
    />
  );
};

export default OurInsightsRiskSnapshot;
